.contract-card {
  padding: 1em 2em;
  background-color: $gray-0;
  display: flex;
  gap: 2em;
  .contract-card-left {
      align-self: flex-start;
      .image {
          width: 40px;
          height: 40px;
        }
    }
    .contract-card-right {
        display: flex;
        flex-direction: column;
        gap: 1em;
        .top {
            .company_name, .contract_type {
                font-weight: $bold;
            }
            .company_address, .contract_type, .date, .status {
                font-size: $size-s;
            }
            .company_address {
                color: $gray-6;
                margin-bottom: 0.5em;
            }
            .date, .status {
                color: $gray-7;
            }
            .status {
                .green {
                    color: $green;
                }
                .red {
                    color: $red;
                }
                .blue {
                    color: $blue;
                }
            }
        }
    }
}
