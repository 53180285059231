.edit-profile-pro {
  @include tablet-landscape() {
    width: 70%;
  }
  h1 {
    margin-bottom: 2em;
  }
  h4 {
    margin-bottom: 1em;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2em;
    @include tablet-landscape() {
      flex-direction: row;
      gap: 10em;
    }
    .left {
      .profile-image {
        width: 200px;
      }
    }
    .right {
      @include tablet-landscape() {
        flex: 2;
      }
      .input-group {
        margin-top: 1em;
        gap: 1em;
      }
      .divider {
        margin-bottom: 2em;
        // .docs-input-group {
        //   .group {
        //     .photo {
        //       position: relative;
        //       .delete {
        //         position: absolute;
        //         top: 0;
        //         right: 0;
        //         padding: 0.3em;
        //         background-color: $red;
        //         color: $white;
        //       }
        //     }
        //   }
        //   .buttons-doc {
        //     button {
        //       padding: 0.3em 0.8em;
        //       margin-right: 0.5em;
        //       &.alt {
        //         font-weight: $regular;
        //         &:hover {
        //           background-color: $black;
        //           color: $white;
        //         }
        //       }
        //     }
        //   }
        // }
        // .docs-input-group {
        //   // .file-input {
        //   //     input[type="file"] {
        //   //         display: none;
        //   //     }
        //   // }
        // }
      }
    }
  }
}
