.besc-page {
  .stepper-zone {
    margin: 2em auto;
  }
  @include tablet-landscape() {
    width: 70%;
  }
  .step-component {
    @include tablet-landscape() {
      margin: 2em 0;
      width: 100%;
    }
    .buttons {
      margin-top: 3em;
    }
  }
  
}
