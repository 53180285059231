.stepper {
    display: flex;
    flex-direction: column;
    gap: 3em;
    margin: 1em 0;
    @include tablet_landscape() {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
    .step {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      gap: 1em;
      cursor: pointer;
      &:hover {
        .step-icon {
          background-color: $beige;
        }
      }
      .step-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.8em;
        background-color: $light-grey;
        border-radius: 50%;
        font-size: 1.1rem;
        transition: all 0.2s ease-in-out;
        position: relative;
        &::after {
          content: "";
          width: 2px;
          height: 220%;
          position: absolute;
          background-color: $light-grey;
          left: 50%;
          z-index: -1;
        }
        @include tablet_landscape() {
          &::after {
            display: none;
          }
        }
      }
      .step-info {
        display: flex;
        flex-direction: column;
        span {
          font-size: 0.75rem;
          color: $subtext;
        }
        strong {
          font-size: 0.83rem;
        }
      }
      &.step-active {
        .step-icon {
          background-color: $green;
          color: $white;
          &::after {
            background-color: $primary;
          }
        }
      }
    }
    .image {
      width: 120px;
      height: 150px;
      background-color: $white;
      margin: 0.5em 0;
      img {
        height: 100%;
      }
    }
   
    .step-files {
      .files {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        gap: 1em;
        .file-wrapper {
          display: flex;
          flex-direction: column;
          p {
            font-size: 0.8rem;
            font-weight: 500;
            color: $dark-grey;
          }
          .upload-btn-wrapper input[type="file"] {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
          }
          .upload-btn-wrapper {
            position: relative;
            overflow: hidden;
            display: inline-block;
          }
          .btn {
            color: $white;
            background-color: $dark-grey;
            padding: 0.5em 1em;
            svg {
              transform: translateY(1px);
            }
          }
        
         &:nth-child(4){
          .image {
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
    
          }
        
         }
        
        }
       
      }
    
      .buttons {
        padding-top: 2em;
        border-top: 1px solid rgba(0, 0, 0, 0.073);
        margin-top: 2em;
      }
    }
  }
  .input-read {
    padding: 4.5px;
    padding-left: 2px;
    border-bottom: 1px solid #e2d9d9;
    display: block;
    font-weight: bold;
    color: $black;
   
  }
  label{
    margin-top: 10px;
  }
  .bkviewImg{
    color:$green;
  }
  