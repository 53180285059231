.login-page {
    width: 90%;
    margin-top: 6em;
    @include tablet-portrait() {
      width: 60%;
    }
    @include tablet-landscape() {
      width: 40%;
    }
    @include desktop() {
      width: 30%;
    }
    form {
      margin: 1.5em 0;
      display: flex;
      flex-direction: column;
      gap: 0.75em;
      input[type="text"], input[type="email"], input[type="password"]{
          background-color: $gray-1;
      }
      button {
          align-self: flex-start;
      }
    }
    .bottom-text {
      .subtext {
          &:first-of-type {
              text-decoration: underline;
              margin-bottom: 1.5em;
              cursor: pointer;
          }
          &:last-of-type {
              a {
                  color: $blue;
              }
          }
      }
    }
  }
  