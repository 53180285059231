.side-menu {
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: $white;
  z-index: 4;
  &.gray {
    background-color: $gray-0;
  }
  ul {
    width: 90%;
    margin-inline: auto;
    margin-top: 5em;
    li {
      margin: 0.5em 0;
    }
  }
  &.open {
    transform: translateX(0);
  }
}
