@import "colors";
@import "mixins";
@import "fonts";

* {
  font-family: $dm-sans;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  max-width: 2150px;
  width: 100%;
  height: 100%;
  margin-inline: auto;
}

.page {
  width: 90%;
  min-height: 90vh;
  margin: 6em auto 2em;
}
.required::after{
  content: '*';
  color:$red;
}
input:required{
  background: 1px solid $red;
}
@media print {
  @page {
    size: A4; /* DIN A4 standard, Europe */
    margin: 20mm;
  }
}

.w-60 {
  @include desktop() {
    width: 60%;
   
  }
}

.w-50 {
  @include desktop() {
    width: 50%;
  }
}

.contract-doc {
  h1 {
    margin-bottom: 2em;
  }
  .presentation {
    margin-bottom: 2em;
    .employeur,
    .client {
      display: flex;
      flex-direction: column;
      gap: 0.5em;
      margin-bottom: 1em;
    }
  }

  .convention {
    text-transform: uppercase;
    font-weight: $bold;
  }

  .articles {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  .note {
    font-style: italic;
  }

  .date {
    text-align: right;
  }

  .nb {
    font-style: italic;
    text-decoration: underline;
  }

  .signatures {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3em;
  }
}

label {
  > * {
    margin-top: 0.3em;
  }
}

select,
input,
textarea,
option {
  padding: 0.56em 0.5em;
  border: none;
  outline: none;
  width: 100%;
  color: $gray-7;
  background-color: $gray-1;
  &:disabled {
    background-color: $gray-3;
  }
}

img {
  width: 100%;
  object-fit: cover;
  display: block;
}

a {
  color: inherit;
}

ul {
  list-style: none;
}

.checkbox,
.radio-button {
  input[type="checkbox"],
  input[type="radio"] {
    vertical-align: middle;
    position: relative;
    padding: 0;
    width: auto;
    bottom: 1px;
  }
  label {
    display: block;
    color: $black;
    > * {
      margin-top: 0;
    }
  }
}
.step-files {
  .files {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 1em;
    .file-wrapper {
      display: flex;
      flex-direction: column;
      p {
        font-size: 0.8rem;
        font-weight: 500;
        color: $dark-grey;
      }
      .upload-btn-wrapper input[type="file"] {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
      }
      .upload-btn-wrapper {
        position: relative;
        overflow: hidden;
        display: inline-block;
      }
      .btn {
        color: $white;
        background-color: $dark-grey;
        padding: 0.5em 1em;
        svg {
          transform: translateY(1px);
        }
      }

    }
  }
}
button {
  border: none;
  outline: none;
  background-color: $black;
  padding: 0.5em 1.5em;
  transition: all 0.3s ease;
  border-radius: 20px;
  //margin: 10px;
  cursor: pointer;
  &.big {
    padding: 0.8em 1.6em;
    border-radius: 30px;
  }
  &.accent {
    background-color: $beige;
    color: $black;
    &:hover {
      background-color: $lighter-beige;
    }
  }
  &.alt {
    background-color: transparent;
    border: 1px solid $gray-5;
    color: $black;
    &:hover {
      background-color: $lighter-beige;
      border: 1px solid $lighter-beige;
    }
  }
  &.primary {
    background-color: $primary;
    color: $gray-0;
    &:hover {
      background-color: $gray-8;
    }
  }
  &.primary-alt {
    color: $black;
    background-color: transparent;
    border: 1px solid $black;
  }
  &.secondary {
    background-color: transparent;
    color: $black;
    border: 1px solid transparent;
    &:hover {
      border: 1px solid $black;
    }
  }
  &.activate {
    background-color: $light-green;
    color: $gray-0;
    &:hover {
      background-color: $lighter-green;
    }
  }
  &.success {
    background-color: rgb(6, 64, 6);
    color: $gray-0;
    &:hover {
      background-color:  rgb(14, 124, 14);;
    }
  }
  &.delete {
    background-color: $red;
    color: $gray-0;
    &:hover {
      background-color: $lighter-red;
    }
  }

  &:disabled {
    background-color: $gray-6;
  }
}

// ? Flex centered and space-between
.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header {
  margin-bottom: 2em;
}

// ? MUI Datagrid
.MuiDataGrid-root {
  border: none !important;
  border-radius: 0 !important;

  // ? Header
  .MuiDataGrid-columnHeaders {
    background-color: $lighter-grey;
    border: none !important;
    font-size: 0.8rem;
    border-radius: 0 !important;
  }

  // ? Separator between headers
  // .MuiDataGrid-columnSeparator {
  //   display: none !important;
  // }

  // ? Toolbar
  .MuiDataGrid-toolbarContainer {
    margin-bottom: 1em;
    gap: 1em;
    .MuiButtonBase-root {
      color: rgba(0, 0, 0, 0.677);
      letter-spacing: 0;
      font-weight: 700;
      padding: 0.5em 0.7em;
      text-transform: none;
      background-color: $lighter-grey;
      border-radius: 0;
      font-size: 0.75rem;
      // ? Icones
      // .css-y6rp3m-MuiButton-startIcon {
      //   color: $primary;
      // }
    }
  }

  // ? Popup colonnes filters in datagrid
  // TODO : Find a way to make this work
  .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
    font-size: $size-s !important;
    font-family: $dm-sans !important;
  }

  // ? Table data
  .MuiDataGrid-cell {
    font-size: $size-s !important;
  }

  // ? Data-grid photo
  .photo {
    height: 30px;
    width: 30px;
    img {
      border-radius: 50%;
    }
  }

  // ? Data-grid buttons
  .options {
    button {
      text-transform: uppercase;
      padding: 0.45em !important;
      margin-right: 1em;
      font-size: 0.7rem;
      color: $primary !important;
      background-color: #fff !important;
      border: 1px solid #000;
      border-radius: 5%;

      &:hover {
        background-color: $primary !important;
        color: $gray-0 !important;
      }
      &.delete {
        color: $red !important;
        border: 1px solid $red;
        &:hover {
          background-color: $red !important;
          color: $gray-0 !important;
        }
      }
      &.active {
        color: #07AF4B !important;
        border: 1px solid $light-green;
  
        &:hover {
          background-color: $white!important;
          color: $primary !important;
        }
      }
    }
  }
}

// ? Messages d'erreurs
.error-msg {
  margin: 0.5em 0;
  font-size: 12px;
}

// ? Info group
.info-group {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 2em;
}

// ? Groupement d'inputs
.input-group {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1em;
  &.file-input {
    input[type="file"] {
      display: none;
    }
    label {
      &.file-input-label {
        color: $black;
        height: 30px;
        width: 175px;
        border: 1px solid $black;
        display: flex;
        align-items: center;
        // justify-content: center;
        padding-left: 5px;
        cursor: pointer;
        margin-top: 17px;
        svg {
          transform: translateY(-2px);
          margin-right: 0.3em;
        }
      }
    }
    .group {
      span {
        display: block;
        color: $gray-6;
        font-size: $size-s;
        font-weight: $regular;
   
      }
    }
  }
}

// ? Custom Modals
.modal-component {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: grid;
  place-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  .modal {
    border-radius: 25px;
    width: 95%;
    max-height: 600px;
    @include tablet-landscape() {
      width: 40%;
    }
    background-color: $gray-0;
    padding: 3em;
    height: auto;
    overflow-y: auto;
    position: absolute;
    // top: 50%;
    // left: 50%;
    transform: translate(-50%, -50%);
    h5 {
      margin: 1em 0 0.1em;
      color: $gray-8;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 0.55em;
      input,
      select,
      textarea {
        border: 1px solid rgba(0, 0, 0, 0.15);
      }
      label {
        color: #000;
        &.password {
          .password-field {
            position: relative;
            .eye-icon {
              position: absolute;
              right: 3%;
              top: 25%;
              font-size: 1rem;
              color: $primary;
              cursor: pointer;
              transition: all 0.3s ease;
              &:hover {
                color: $gray-6;
              }
            }
          }
        }
      }
      button {
        margin-top: 1.5em;
      }
    }

    .close {
      position: absolute;
      top: 5%;
      right: 5%;
      #close-icon {
        color: $primary;
        cursor: pointer;
      }
    }
  }

  .profile-image {
    height: 150px;
    width: 150px;
  }
}

// ? Recharts
.recharts-cartesian-axis-tick {
  font-size: 0.8rem;
}

// ? input field with icon at the right
.search-field {
  position: relative;
  .icon {
    position: absolute;
    right: 3%;
    top: 25%;
    font-size: 1rem;
    color: $primary;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      color: $gray-6;
    }
  }
}
.bold{
  font-weight: 900;
  color: #000;
  text-decoration: underline;
}
.link,.social svg:hover:hover{
  color: #023402;
}
 .link:active{
  color: #ddd;
}
.social svg{
  cursor: pointer;
 
}

// .page:hover ~ #notification-message{
//   background-color: #000
// }
@import "../components/Navbar/Navbar";
@import "../components/NavbarAdmin/NavbarAdmin";
@import "../components/SideMenu/SideMenu";
@import "../components/HomeSearchBar/HomeSearchBar";
@import "../components/ProfessionalCard/ProfessionalCard";
@import "../components/Footer/Footer";
@import "../components/Spinner/Spinner";
@import "../components/UserMenuDropdown/UserMenuDropdown";
@import "../components/ProfileCard/ProfileCard";
@import "../components/ContractCard/ContractCard";
@import "../components/CandidatCard/CandidatCard";
@import "../components/RecruiterCard/RecruiterCard";
@import "../components/ProFiltersModal/ProFiltersModal";
@import "../components/RecruiterDetailsModal/RecruiterDetailsModal";
@import "../components/ContractDetailsModal/ContractDetailsModal";
@import "../pages/Home/Home";
@import "../pages/FindProfessionals/FindProfessionals";
@import "../pages/FindRecruiters/FindRecruiters";
@import "../pages/Login/Login";
@import "../pages/SignUp/SignUp";
@import "../pages/SignUpPro/SignUpPro";
@import "../pages/DashboardPro/DashboardPro";
@import "../pages/EditProfilePro/EditProfilePro";
@import "../pages/AccountSettings/AccountSettings";
@import "../pages/ProfilePro/ProfilePro";
@import "../pages/MyContracts/MyContracts";
@import "../pages/DashboardAdmin/DashboardAdmin";
@import "../pages/ProfileRecruiter/ProfileRecruiter";
@import "../pages/NotFound/NotFound";
@import "../pages/PrintContract/PrintContract";
@import "../components/Stepper/Stepper";
@import "../pages/NewRecrutement/NewRecrutement";
@import "../pages/UtilisateurList/UtilisateurList";
@import "../pages/MetierList/MetierList";
@import "../components/MetierDetailsModal/MetierDetailsModal";
@import "../components/UserDetailsModal/_UserDetailsModal";
@import "../pages/Contact/Contact.css";
@import "../pages/About/About.css";

