.recruiter-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5em;
  background-color: $gray-0;
  padding: 2em;
  max-width: 250px;
  position: relative;
  .image {
    width: 50px;
    height: 50px;
    margin-bottom: 1em;
  }
  .name {
    font-size: $size-m;
    font-weight: $bold;
  }
  .address {
    font-size: $size-s;
    color: $gray-7;
  }
  .description {
    font-size: $size-s;
    margin: 1em 0;
    color: $gray-8;
  }
  .activities {
    font-size: $size-s;
    color: $gray-7;
    font-weight: $bold;
  }
  .badge {
    position: absolute;
    top: 0%;
    right: 0%;
    font-size: $size-s;
    padding: 0.4em;
    text-transform: capitalize;
    &.green {
        background-color: $light-green;
        color: white;
    }
    &.purple {
        background-color: $light-purple;
        color: white;
    }
  }
}
