.pro-filters-modal {
  @include desktop() {
    width: 30% !important;
  }
  form {
      .categories-list {
        // margin-top: 1em;
        height: 100px;
        overflow-y: scroll;
        .checkbox {
          input[type="checkbox"] {
            margin-right: 0.5em;
          }
          label {
            color: $gray-8;
          }
        }
      }
      .localisation {
        label {
            color: $gray-8;
        }
      }
      input[type="text"], select {
        border: none !important;
        padding: 0.5em 1em;
        border-radius: 10px;
      }
  }
}
