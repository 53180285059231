.home-searchbar {
  border-radius: 30px;
  position: relative;
  height: 60px;
  border: 2px solid #000;
  .icon,
  button {
    position: absolute;
  }
  input[type="text"] {
    border-radius: 30px;
    width: 100%;
    height: 100%;
    padding-inline: 2.5em 5em;
    background-color: $white;
    @include tablet-portrait() {
        padding-inline: 3em 5em;
    }
    @include tablet-landscape() {
        padding-inline: 3.5em 5em;
    }
    @include desktop() {
        padding-inline: 4em 5em;
    }
  }
  .icon {
    top: 50%;
    left: 3%;
    transform: translateY(-40%);
    color: $gray-4;
  }
  button {
    right: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    border-radius: 30px;
  }
}
