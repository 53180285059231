.navbar {
  transition: all 0.3s ease;
  border-bottom: 1px solid $gray-1;
  &.gray {
    background-color: $gray-0;
    border-bottom: 1px solid $gray-0;
  }
  background-color: $white;
  position: fixed;
  width: 100%;
  z-index: 5;
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin-inline: auto;
    padding: 1em 0;
    .link-active {
      font-weight: $bold;
    }
    .left {
      display: flex;
      align-items: center;
      z-index: 2;
      .menu {
        margin-right: 0.5em;
        display: flex;
        flex-direction: column;
        gap: 0.4em;
        @include tablet-landscape() {
          display: none;
        }
        .bar {
          transform-origin: left;
          transition: transform 0.2s ease;
          height: 3px;
          width: 25px;
          background-color: $black;
        }
        &.menu-open {
          .bar {
            &.top {
              transform: rotate(45deg) translateY(-5px);
            }
            &.bottom {
              transform: rotate(-45deg) translateY(5px);
            }
          }
        }
      }
      .logo {
        font-weight: $bold;
        width: 50px;
      }
    }
    .center {
      display: none;
      @include tablet-landscape() {
        display: block;
      }
      ul {
        display: flex;
        align-items: center;
        gap: 2em;
        li {
          font-size: $size-s;
        }
      }
    }
    .right {
      @keyframes fill {
        from {
          opacity: 0%;
          height: 0%;
        }
      }
     display: flex;
     flex-direction: row;
     gap: 1rem;
      // .login-btn {
      //   position: relative;
      //   transition: all 0.4s ease;
      //   overflow: hidden;
      //   &::after {
      //     content: "";
      //     position: absolute;
      //     bottom: 0;
      //     left: 0;
      //     right: 0;
      //     width: 100%;
      //     height: 0;
      //     opacity: 0;
      //     background-color: $black;
      //     border-radius: 20px;
      //     transition: all 0.3s ease;
      //     z-index: -1;
      //   }
      //   &:hover {
      //     color: $white;
      //     border: 1px solid $black;
      //     &::after {
      //       height: 100%;
      //       opacity: 1;
      //     }
      //   }
      // }

      .user-menu {
        display: flex;
        align-items: center;
        gap: 0.5em;
        cursor: pointer;
        position: relative;
        .profile-image {
          width: 30px;
          height: 30px;
          img {
            height: 100%;
            border-radius: 50%;
          }
        }
        .arrow-icon {
          transform: translateY(4px);
          svg {
            transition: transform 0.3s ease;
          }
          &.open {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
}
.notifaction{
  padding:6px 10.5px;
  cursor: pointer;
}
.notification-icon{
  position: relative;
  vertical-align: middle;
}
.notification-number{
  position:absolute;
  top:-8px;
  left:10px;
  border-radius: 10px;
  background-color: #d70808;
  height: 19px;
  width: 19px;
  padding-top: 1px;
  text-align: center;
  color: #fff;
  font-size: 0.8rem;
  font-weight: 800;
  z-index: 1;
}
.notification-message{
  background-color: #fff;
  width: 320px;
  position: absolute;
  height: 254px;
  right: 12px;
  box-shadow: 0px 0px 3px 2px #f5f0f0;
  
}
.notification-message .fleche{
  background-color:#fff;
  height: 25px;
  width: 25px;
  margin: auto auto;
  transform: rotate(45deg);
  border-radius: 6px 0px 0px 0px;
  margin-top: -8px;
}
.notification-message ul {
  overflow-y: scroll;
  position: absolute;
  top:0px;
  padding: 9px;
  width: 100%;
  height: 100%;
}
.notification-message ul li {
  color: #716c6c;
  font-weight: 500;
  font-family: Arial, Helvetica, sans-serif;
}
.notification-message .user-profile{
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  border-bottom: 1px solid #d3cece;
}
.notification-message .user-profile .user-picture{
  width: 62px;
  height: 82px;
  padding-top: 7.3px;
  padding-bottom: 10px;
}
.notification-message .user-profile .user-picture img{
  width: 100%;
  height: 100%;
  
}
.user-detail{
  padding-left: 10px;
  width: 100%;
}
.user-detail p{
   color: #716c6c;
   font-size: 13px;

}
.user-detail p:last-child{
 
  width: 100%;
}



