.user-menu-dropdown {
  background-color: $gray-0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  position: absolute;
  top: 140%;
  right: 0%;
  width: 200px;
  padding: 1.1em 1.1em;
  display: flex;
  flex-direction: column;
  gap: 0.6em;
  z-index: 3;
  > * {
    display: flex;
    align-items: center;
    gap: 1em;
    color: $gray-7;
    .icon {
      transform: translateY(2px);
      transition: all 0.2s ease;
    }
    .navigation {
      transition: all 0.2s ease;
    }
    &:hover {
      .navigation {
        color: $black;
      }
      .icon {
        color: $orange;
      }
    }
  }
}
