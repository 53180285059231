.photo-input {
    .group {
        .photo {
            width: 100px;
            margin-bottom: 1em;
        }
    }
}
// .alert-danger{
//     display: grid;
//     grid-template-columns: 0.42fr 1fr;
//   }
//   .alert-danger ul{
//     grid-column: 2 / 2;
//   }