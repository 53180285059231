.dashboard-admin {
  .admin-stats {
    margin: 2em 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 2em;
    .box {
      background-color: $gray-0;
      padding: 1.5em 1em;
      display: flex;
      gap: 1em;
      &:nth-of-type(1) {
        .icon {
          background-color: #efefd0;
        }
      }
      &:nth-of-type(2) {
        .icon {
          background-color: #f7c59f;
        }
      }
      &:nth-of-type(3) {
        .icon {
          background-color: #ff9670;
        }
      }
      &:nth-of-type(4) {
        .icon {
          background-color: #6bbfff;
        }
      }
      .icon {
        padding: 0.5em 0.7em;
        align-self: flex-start;
        border-radius: 50%;
      }
      .text {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        .title {
          font-size: 0.85rem;
        }
      }
      .value {
        strong {
          font-size: 1.2rem;
        }
      }
      .more {
        align-self: flex-start;
        text-decoration: underline;
        a {
          color: $blue;
          font-size: $size-s;
        }
      }
    }
  }

  .charts {
    .charts-boxes {
      margin-top: 1em;
      display: grid;
      gap: 4em;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
    .box {
      background-color: $gray-0;
      padding: 1.5em 2em;
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2em;
        select {
          width: 20%;
          font-size: 0.75rem;
          padding: 0.3em;
          text-transform: uppercase;
        }
      }
    }
  }
}
.carte-conteneur{
  display: none;
}
/* Large screen */
@media screen and (min-width: 700px) {
  .carte-conteneur{
    display: grid;
    grid-template-columns: 80px 1fr;
    margin-top: -140px;
   
  }
  .carte-conteneur .carte,.carte-conteneur .stat {
    padding: 22px;
  }
  .carte-conteneur .stat{
    align-self: flex-end;
  }
  .carte-conteneur .carte img{
    width: 60rem;
    height: 45rem;
  
  }
}


