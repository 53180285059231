.spinner {
  height: 100vh;
  width: 100%;
  background-color: $beige;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  .container {
    width: 150px;
    overflow: hidden;
    height: 50px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .rolling-cube {
        height: 20px;
        width: 20px;
        position: absolute;
        left: -25%;
        background-color: $black;
        animation: roll 2s ease-out infinite;
      }
      @keyframes roll {
        from {
          transform: rotate(0deg);
        }
        to {
          left: 110%;
          border-radius: 50%;
          transform: rotate(500deg);
        }
      }
    }
  }
