.dashboard-pro {
  .right {
    margin-top: 2em;
  }

  @include tablet-landscape() {
    width: 90%;
    display: flex;
    gap: 2em;
    .left {
      flex: 1;
    }
    .right {
      margin-top: 0;
      flex: 2;
    }
  }

  @include desktop() {
    width: 80%;
    gap: 4em;
    .right {
      flex: 3;
    }
  }

  .contracts {
    margin-top: 4em;
    h3 {
      border-bottom: 1px solid $black;
    }
    .contracts-list {
      margin-top: 2em;
      .contract-card {
        margin-bottom: 1em;
      }
    }
  }
}
