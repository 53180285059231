.professional-card {
  display: flex;
  justify-content: space-between;
  background-color: $white;
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  gap: 2em;
  text-align: left;
  .top {
    display: flex;
    flex-direction: column;
    gap: 1em;
    .image {
      height: 50px;
      width: 50px;
      img {
        border-radius: 10px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
      }
    }
    .infos {
      .name {
        font-size: $size-m;
        font-weight: $bold;
      }
      .job {
        font-size: $size-s;
        color: $gray-7;
      }
      .badges {
        display: flex;
        align-items: center;
        gap: 0.5em;
        margin-top: 0.5em;
        > * {
          padding: 0.25em 0.6em;
          border-radius: 5px;
          font-size: $size-s;
        }
        .address {
          display: flex;
          gap: 0.5em;
          color: $primary-blue;
          background-color: $light-blue;
          align-self: flex-start;
        }
        .experience-badge {
          background-color: $lighter-green;
          color: $green;
        }
      }
    }
    .description {
        p {
            font-size: $size-s;
            color: $gray-7;
        }
    }
  }
  .bottom {
    :nth-child(2) {
      margin-left: 0.6em;
    }
  }
  
}
.line{
  border-bottom: 2px solid $gray-5;
  margin-top: 20px;
}
