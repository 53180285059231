.recruiter-details-modal {
  .modal {
    @include tablet-landscape() {
      width: 60%;
    }
  }
  .details-recruiter {
    display: flex;
    flex-direction: column;
    gap: 1em;
    .logo {
      width: 50px;
    }
    .divider {
      margin-bottom: 1em;
      h4 {
        margin-bottom: 0.5em;
      }
      .info-box {
        margin-bottom: 0.5em;
        .label {
          display: block;
        }
        a {
          color: $blue;
          font-weight: $regular;
          text-decoration: underline;
        }
        .info {
          font-size: $size-s;
          font-weight: $bold;
          display: inline-block;
          max-width: 196px;
          overflow-wrap:anywhere;
        }
      }
    }
  }
}
.modal-component .modal form label {
  color: #948990;
}
