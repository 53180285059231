.navbar {
  transition: all 0.3s ease;
  border-bottom: 1px solid $gray-1;
  &.gray {
    background-color: $gray-0;
    border-bottom: 1px solid $gray-0;
  }
  background-color: $white;
  position: fixed;
  width: 100%;
  z-index: 5;
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin-inline: auto;
    padding: 1em 0;
    .link-active {
      font-weight: $bold;
    }
    .left {
      display: flex;
      align-items: center;
      z-index: 2;
      .menu {
        margin-right: 0.5em;
        display: flex;
        flex-direction: column;
        gap: 0.4em;
        @include tablet-landscape() {
          display: none;
        }
        .bar {
          transform-origin: left;
          transition: transform 0.2s ease;
          height: 3px;
          width: 25px;
          background-color: $black;
        }
        &.menu-open {
          .bar {
            &.top {
              transform: rotate(45deg) translateY(-5px);
            }
            &.bottom {
              transform: rotate(-45deg) translateY(5px);
            }
          }
        }
      }
      .logo {
        font-weight: $bold;
        width: 50px;
      }
    }
    .center {
      display: none;
      @include tablet-landscape() {
        display: block;
      }
      ul {
        display: flex;
        align-items: center;
        gap: 2em;
        li {
          font-size: $size-s;
        }
      }
    }
    .right {
      @keyframes fill {
        from {
          opacity: 0%;
          height: 0%;
        }
      }
      // .login-btn {
      //   position: relative;
      //   transition: all 0.4s ease;
      //   overflow: hidden;
      //   &::after {
      //     content: "";
      //     position: absolute;
      //     bottom: 0;
      //     left: 0;
      //     right: 0;
      //     width: 100%;
      //     height: 0;
      //     opacity: 0;
      //     background-color: $black;
      //     border-radius: 20px;
      //     transition: all 0.3s ease;
      //     z-index: -1;
      //   }
      //   &:hover {
      //     color: $white;
      //     border: 1px solid $black;
      //     &::after {
      //       height: 100%;
      //       opacity: 1;
      //     }
      //   }
      // }

      .user-menu {
        display: flex;
        align-items: center;
        gap: 0.5em;
        cursor: pointer;
        position: relative;
        .profile-image {
          width: 30px;
          height: 30px;
          img {
            height: 100%;
            border-radius: 50%;
          }
        }
        .arrow-icon {
          transform: translateY(4px);
          svg {
            transition: transform 0.3s ease;
          }
          &.open {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
}
