.sign-up {
  @include tablet-landscape() {
    width: 70%;
  }
  .header {
    .alt {
        display: flex;
        align-items: center;
        gap: 1em;
        margin-top: 1em;
        padding: 1em 2em;
        background-color: $gray-1;
        .icon {
            transform: translateY(2px);
            color: $blue;
        }
    }
  }
  form {
    .divider {
      margin-top: 2em;
      .left {
        margin-bottom: 1em;
        @include tablet-landscape() {
          margin-bottom: 0;
        }
        h4 {
          margin-bottom: 0.2em;
          color: $gray-8;
        }
      }
      @include tablet-landscape() {
        display: flex;
        justify-content: space-between;
        gap: 4em;
        .left {
          flex: 1;
        }
        .right {
          flex: 3;
        }
      }
      .input-group {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
        gap: 1.5em;
        margin-bottom: 1em;
      }
      &.account-type {
        align-items: center;
        .input-group {
          display: flex;
          margin-bottom: 0;
          label {
            color: $gray-7;
          }
        }
      }
      &.submit {
        button {
            margin-top: 1em;
            font-size: $size-m;
        }
      }
    }
  }
}

