.profile-recruiter {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  .logo {
    width: 120px;
    margin-bottom: 1em;
  }
  .infos {
    display: flex;
    flex-direction: column;
    gap: 1em;
    .group {
      .label {
        display: block;
        font-weight: $bold;
        margin-bottom: 0.1em;
      }
      p {
        color: $gray-8;
      }
      &.description {
        @include tablet-landscape() {
          width: 80%;
          margin-inline: auto;
        }
      }
    }
  }
}
