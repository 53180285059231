$primary : #000;
// $primary : #16d196;

$primary-blue: #004E89;
$secondary-blue: #1A659E;
$beige: #F4EBBE;
$lighter-beige: #fff8d4;
$peach: #F7C59F;
$orange: #FF6B35;

$gray-0: #F8F9FA;
$gray-1: #F1F3F5;
$gray-2: #E9ECEF;
$gray-3: #DEE2E6;
$gray-4: #CED4DA;
$gray-5: #ADB5BD;
$gray-6: #868E96;
$gray-7: #495057;
$gray-8: #343A40;
$gray-9: #212529;

$green : #019474;
$light-green : #7cd099;
$lighter-green : #d5ffe4;
 
$purple : #7d7fff;
$light-purple : #9c9dff;
$lighter-purple : #b6b7ff;
$lightest-purple : #d4d5ff;

$blue : rgb(59, 112, 255);
$light-blue : #d1ebff;

$white : #fff;
$lighter-grey : #f9f9f9;
$subtle-grey:  rgba(0, 0, 0, 0.15);
$light-grey : #f0f5f7;
$dark-grey : #8d99ae;
$darker-grey : #2b2d42;
$black: #000;
$red: #e63946;
$lighter-red: #ff616e;
$brown: #e76f51;
$yellow: #e9c46a;
$night-blue: #023e8a;
$subtext:#272825;
$green:#587a34;
$bluenav:#16475e29