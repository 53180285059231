.profile-pro {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1em;
  margin-inline: auto;
  .right {
    display: flex;
    flex-direction: column;
    gap: 2em;
    .group {
      .status {
        .badge {
          padding: 0.1em 0.4em;
          border-radius: 4px;
          font-size: $size-s;
          &.green {
            background-color: $light-green;
            color: $white;
          }
          &.red {
            background-color: $red;
            color: $white;
          }
        }
      }
      .sub-group {
        margin-bottom: 0.5em;
      }
      .link {
        text-decoration: underline;
      }
    }
  }
  @include tablet-landscape() {
    text-align: left;
    align-items: flex-start;
    flex-direction: row;
    width: 60%;
    gap: 10em;
  }
  .left {
    .photo {
      width: 150px;
      max-height: 210px;
    }
  }
}
