.recruiter-details-modal {
  .modal {
    @include tablet-landscape() {
      width: 60%;
    }
  }
  .details-recruiter {
    display: flex;
    flex-direction: column;
    gap: 1em;
    .logo {
      width: 50px;
    }
    .divider {
      margin-bottom: 1em;
      h4 {
        margin-bottom: 0.5em;
      }
      .info-box {
        margin-bottom: 0.5em;
        .label {
          display: block;
        }
        a {
          color: $blue;
          font-weight: $regular;
          text-decoration: underline;
        }
        .info {
          font-size: $size-s;
          font-weight: $bold;
          display: inline-block;
          max-width: 196px;
          overflow-wrap:anywhere;
        }
      }
    }
  }
}
.details-metier .description-group{
  display: block;
}
.details-metier .description{
  display: block;
  width: 100%;
  border: 1.5px solid #ede9e9;
  clear: both;
  border-radius: 8px;
}
.details-metier .description-text{
  padding: 15px;
  font-size: 15px;
  color: #100f0f;
}
