.account-settings {
  @include tablet-landscape() {
    width: 45%;
  }
  h1 {
    margin-bottom: 1em;
  }
  form {
   display: flex;
   flex-direction: column;
   gap: 0.5em;
   button {
    margin-top: 1em;
    align-self: flex-start;
   }
  }
  .delete-account {
    background-color: $gray-0;
    padding: 2em;
    margin-top: 3em;
    .link {
        color: $red;
        text-decoration: underline;
    }
  }
}
.question-phrase{
  color: gray;
  margin-top: -15px;
  font-weight: 800;
}
