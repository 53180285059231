@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Source+Serif+Pro:wght@400;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Fraunces:opsz,wght@9..144,400;9..144,500;9..144,600;9..144,700&display=swap');

$dm-sans: "DM Sans", sans-serif;
$source-serif: "Source Serif Pro", serif;
$fraunces: 'Fraunces', serif;

// ? Headers
// ! Desktop
$size-s: 0.875rem; //14px
$size-m: 1rem; //16px
$size-l: 1.25rem; //20px
$size-xl: 1.5rem; //24px
$size-xxl: 2rem; //32px

// ? Font weights
$regular: 400;
$medium: 500;
$bold: 700;

h1 {
  font-size: $size-xxl;
}

h2 {
  font-size: $size-xl;
}

h3 {
  font-size: $size-l;
}

h4 {
  font-size: $size-m;
}

h1, h2, h3, h4 {
  font-family: $fraunces;
}

label {
  color: $gray-6;
  font-size: $size-s;
  font-weight: $regular;
}

select,
input,
textarea {
  font-size: $size-m;
  @include tablet-landscape() {
    font-size: $size-s;
  }
}

// option {
//   font-family: $dm-sans !important;
//   font-weight: $regular;
// }

a {
  text-decoration: none;
}

p {
  font-size: $size-m;
}


button {
    color: $white;
    font-weight: $medium;
    font-size: $size-s;
}

.error-msg {
    color: $red;
    font-size: $size-s;
    font-weight: $bold;
}

.subtext {
  color: $gray-7;
  font-size: $size-s;
}

.link {
    color: $blue;
}

// li {
//   font-size: $size-s;
// }
