.find-professionals {
  margin-top: 6em;
  @include tablet-landscape() {
    width: 85%;
  }
  .search {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1em;
    margin-top: 1em;
    @include tablet-landscape() {
      flex-direction: row;
    }
    input,
    select {
      background-color: transparent;
      border: 2px solid $gray-2;
      padding: 1em 3em;
      border-radius: 10px;
    }
    select {
      -webkit-appearance: none;
      appearance: none;
    }

    .job,
    .location,
    .experience {
      position: relative;
      .icon {
        position: absolute;
        top: 52%;
        left: 4%;
        transform: translateY(-45%);
        color: $gray-5;
        &.arrow-icon {
          left: unset;
          right: 6%;
          top: 57%;
        }
      }
    }
    .job {
      width: 100%;
      @include tablet-landscape() {
        width: 40%;
      }
      input {
        padding-left: 3.5em;
      }
    }
    .location {
      width: 100%;
      @include tablet-landscape() {
        width: 30%;
      }
    }
    .experience {
      width: 100%;
      @include tablet-landscape() {
        width: 20%;
      }
      select {
        padding: 1em 2em;
      }
    }

    .button-and-filters {
      display: flex;
      align-items: center;
      gap: 2em;
      height: 45px;
      width: 100%;
      @include tablet-landscape() {
        width: auto;
      }
      button {
        height: 100%;
        width: 100%;
      }
      .filters {
        padding: 0.5em;
        background-color: $beige;
        border-radius: 10px;
        cursor: pointer;
        &:hover {
          background-color: $lighter-beige;
          transition: all 0.3s ease;
        }
        .icon {
          transform: translateY(2px);
        }
      }
    }
  }

  .categories-badges {
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin: 1em 0 0;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    article {
      padding: 0.3em 0.6em;
      background-color: $gray-1;
      border-radius: 5px;
      white-space: nowrap;
      font-size: $size-s;
      color: $gray-7;
      transition: all 0.3s ease;
      cursor: pointer;
      &:hover {
        background-color: $gray-3;
      }
    }
  }

  .list {
    margin-top: 2em;
    .stats {
      font-size: $size-s;
      color: $gray-7;
    }
    .pro-cards {
      margin: 1em 0;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
      gap: 1em;
      .professional-card {
        background-color: $gray-1;
      }
    }
  }
}
