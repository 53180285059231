.my-contracts-page {
  width: 70%;
  h1 {
    margin-bottom: 2em;
  }
  main {
    display: flex;
    flex-direction: column;
    @include tablet-landscape() {
      flex-direction: row;
      gap: 2em;
    }
    justify-content: space-between;
    gap: 4em;
    .contracts {
      order: 2;
      @include tablet-landscape() {
        flex: 2;
        order: 1;
      }
      .contract-card {
        margin-bottom: 1em;
      }
    }
    .stats {
      order: 1;
      width: 100%;
      @include tablet-landscape() {
        order: 2;
        width: fit-content;
      }
      h4 {
        margin-bottom: 1em;
      }
      background-color: $lighter-beige;
      padding: 1em 2em;
      align-self: flex-start;
    }
  }
}
