@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Source+Serif+Pro:wght@400;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Fraunces:opsz,wght@9..144,400;9..144,500;9..144,600;9..144,700&display=swap');
/*Small scrren */
 
/*End Small scrren */
.principal-about-header{
    display: flex;
    flex-direction: column;
 
}
.page-header-left{
    width: 100%;
   
}
.page-header-right,.page-header-left,.main-about .title-left,.main-description,.main-experience{
    padding: 10px;
}
.page-header-left h5{
   color: #605e5e; 
   text-transform: uppercase;
}
.page-header-left .title{
    width: 350px;
    word-wrap: break-word;
    padding-bottom: 15px;
}
.page-header-left .description{
    padding-top: 15px;
}
.page-header-left .description p:nth-last-child(2),.page-header-left .description button {
  padding-bottom: 10px;
}
.page-header-left .description p,.main-description,.main-experience .experience p{
    color: #605e5e;   
}
.page-header-left  .success-about{
  border-radius: 0;
}
.page-header-right{
    flex: 1;
    width: 100%;
    margin-top: 20px;
}
/* main content */
.main-wrapper{
   background-color: #e6e1e117;
   background-image: url('https://www.vidyard.com/wp-content/themes/vidyard-website/img/pages/company/about-us/group-doodle.svg');
   width: 100%;
   position: relative;
   padding-bottom: 99px;
}
.main-about,.main-experience{
  
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}

.main-about .title-left{
    width: 100%;
}
.main-about .title-left h2{
  line-height: 25px;
}
.main-description{
    width: 100%;
}
.page-header-left .title h3 {
    text-transform: uppercase;
}
.page-header-left .link:hover{
    color: #023402;
}
.page-header-left .link:active{
    color: #ddd;
}
.main-experience{
    display: flex;
    flex-direction: column;
}

.main-experience .experience h4{
    text-align: center;
    font-size: xx-large;
    word-wrap: break-word;
}
.photo-illustration{
    width: 350px;
    height: 250px;
    background-color: bisque;
    margin: auto;
    margin-top: -69px;
    min-width: 150px;
}
.photo-illustration .about-image{
    padding: 0;
    width: 100%;

}
.photo-illustration .about-image img{
    height: 250px;
}
/* main  */
.main-experience .experience{
    width: 99%;
    height: 180px;
    padding: 12px;
    border: .5px solid #fdf5f5;
    transition: all 1s ease-in;
    cursor: pointer;
    text-align: center;
  
}
.main-experience .experience:hover{
    background-color: #fcfcfc;
    box-shadow:0px 0px 4px 2px #bdb9b9;
}
.main-wrapper .divider{
    display: none;
}
.main-about .title-left h2{
    text-transform: uppercase;
}
.main-about .divider-vertical{
    display: none;
}
/* end main */
.curious-content .success-about{
    border-radius: 0;
    background-color: #020244e5;
    padding: 1em 2em;
 }
 .curious-content .success-about:hover{
    background-color: #090983de;
 }
 .curious-content p{
    color: #151515c1;
 }
 .curious{
    margin-top: 99px;
    width: 100%;

   }
   .curious-content{
     display: flex;
     flex-direction: column;
   }
   .histoire{
    margin-top: 65px;
    margin-bottom: 60px;
    padding: 10px;
   }
   .histoire .histoire-titre h4{
    text-align: center;
    font-size: 24px;
    color: #151515c1;
    text-transform: uppercase;
   }
   .histoire .histoire-sous-titre h5,.histoire-titre h4{
    font-size: 24px;
    font-weight: 800;
    margin-top: 30px;
    text-align: center;
   } 
   .histoire-sous-titre p{
      margin: 20px;
      padding: 20px;
      max-width: 340px;
      margin: auto;
   }
   .main-experience .experience{
      height: inherit;
    }
   .spacer{
    margin: 80px;
   }
   .about-image img{
     background-color: #ddd;
   }
/* end main content */
/* Large screen */
@media screen and (min-width: 700px) {
   .principal-about-header{
     flex-direction: row;
     gap: 1rem;
   }
   .page-header-left{
    width: 55%;
   }
   .about-image {
    padding-top: 10px;
    width: 85%;
    box-shadow: 3px 2px 4px 4px #fff;
   }
   .page-header-left .title{
     width: 100%;
   }
   .main-about{
    margin-top: 20px;
    flex-direction: row;
   }
   .main-about hr{
    visibility: hidden;
   }
   
   /*main-wrapper 8*/
   .main-experience{
    flex-direction: row;
    gap: 1rem;
   }
   .main-experience .experience h4{
    text-align: left;

   }
   .main-about .title-left{
      display: flex;
      align-items: center;
      justify-content: center;
    }
   .main-wrapper .divider{
    display: block;
    width: 80%;
    height: 2px;
    margin: auto;
    background-color: #ddd;
    margin-top:80px;
    margin-bottom:80px;
   }
   .main-experience .experience{
        width: 370px;
        height: inherit;
        padding: 12px;
        transition: all 1s ease-in;
        cursor: pointer;
        text-align: left;
  
   }
   .main-experience .experience h4{
    text-align: center;
   }
   .main-experience{
     flex-wrap: wrap;
     justify-content: center;

   }
   .main-about .divider-vertical{
       display: block;
       height:inherit; 
       border-right: 4px solid #16475e;
       margin: 10px;
   }

   /* end  main */
   .curious-content{
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     align-items: center;
     width: 1050px;
     height: 90px;
     background-color: #fff;
     margin: auto;
     box-shadow:0px 0px 4px 2px #ededed;
     padding: 20px;
    
   }
   .curious-content div:first-child{
    width: 80%;
   }
   .photo-illustration2{
    width: 100%;
    height: 700px;
    background-color: #605e5e;
    margin-top: 60px;

   }
   .photo-illustration2 img{
    width: 100%;
    height: 100%;
    object-fit: cover;
   }
   .histoire-sous-titre p{
    margin: 20px;
    padding: 20px;
    max-width: 800px;
    margin: auto;
 }
}
/* end large screen */