footer {
    background-color: $black;
    color: $gray-2;
    padding: 6em 1em;
    @include tablet-landscape(){
        padding: 6em;
    }
    .content {
        width: 90%;
        margin-inline: auto;
        @include tablet-landscape(){
            width: 70%;
        }
        h1 {
            margin-bottom: 1em;
            color: $white;
        }
        .infos {
            margin-top: 6em;
            display: flex;
            flex-direction: column;
            gap: 2em;
            @include tablet-landscape(){
                flex-direction: row;
                justify-content: space-between;
            }
            .left {
                p {
                    margin-bottom: 0.5em;
                }
                .social {
                    margin-top: 2em;
                    display: flex;
                    align-items: center;
                    gap: 1em;
                    color: $white;
                }
            }
            .right {
                display: flex;
                flex-direction: column;
                margin-top: 1em;
                gap: 0.5em;
                @include tablet-landscape() {
                    flex-direction: row;
                    justify-content: space-between;
                    gap: 6em;
                    margin-top: 0;
                }
                nav {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5em;
                }
            }
        }
        .copyright {
            border-top: 2px solid $gray-2;
            padding-top: 0.5em;
            margin-top: 4em;
            font-weight: $bold;
            font-size: $size-s;
        }
    }
}