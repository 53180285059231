@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Source+Serif+Pro:wght@400;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Fraunces:opsz,wght@9..144,400;9..144,500;9..144,600;9..144,700&display=swap');
/*Small scrren */
  .section-principal{
   background-color: #262525;
  }
  .section-principal .entete,.section-principal .corps{
    display: flex;
    flex-direction: column;
    color:#f2ebeb;
    font-family: 'Fraunces', serif;
  }
  .section-principal .entete, .section-principal .corps{
    padding: 16px;
  }
  .section-principal .entete p{
    color: #c5b9b9;
  }
  .section-principal .corps .information p{
    font-size: 12px;
  } 
  .section-principal .corps .information .social{
    margin-top: .5em;
    display: flex;
    align-items: center;
    gap: 1em;
    color: #fff;
 
  }
  #map{
    margin-top: 10px;
    height: 95.5%;

  }
  #map iframe{
    width: 100%;
    height: 75%;
    background-color: #ddd;
  }
  .input .error-msg{
    display: block;
  }
/*End Small scrren */
/* Large screen */
@media screen and (min-width: 1484px) {
  .section-principal .corps{
    display: grid;
    grid-template-columns: 450px 1fr; 
    grid-gap: 2px;
  }
  .section-principal{
    width: 70%!important;
    min-height: 70vh!important;
  }
  .section-principal .corps .input input{
     margin-top: 10px;
  }
  .section-principal .corps .information{
    margin:21px;
  
  }
}
/* end large screen */