.contract-details-modal {
    .modal {
      @include tablet-landscape() {
        width: 40%;
      }
    }
    .details-contract {
      display: flex;
      flex-direction: column;
      gap: 1em;
      .divider {
        margin-bottom: 1em;
        h4 {
          margin-bottom: 0.5em;
        }
        .card {
            display: flex;
            gap: 1em;
            .card-image {
                width: 50px;
                height: 50px;
            }
            .card-infos {
                display: flex;
                flex-direction: column;
                a {
                    text-transform: uppercase;
                    font-size: $size-s;
                }
            }
        }
        &.bottom {
            margin-top: 2em;
        }
      }
      .buttons {
        .delete {
            margin-left: 0.5em;
        }
      }
    }
  }
  