.home {
  width: 100%;
  min-height: 100vh;
  .banner {
    background-color: $gray-0;
    // background-image: linear-gradient(to top, #fff1eb 0%, #ace0f9 100%);
    // background-image: url("https://images.unsplash.com/photo-1621905252507-b35492cc74b4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80");
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-attachment: scroll;

    // ? Darken the background for text readability purposes
    // &::after {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   bottom: 0;
    //   left: 0;
    //   right: 0;
    //   background: rgba(0, 0, 0, 0.3);
    //   pointer-events: none;
    // }
    height: 500px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .content {
      width: 80%;
      text-align: center;
      position: relative;
      z-index: 1;
      .search-zone {
        @include tablet-landscape() {
          margin-inline: auto;
          width: 60%;
          h1 {
            width: 70%;
            margin-inline: auto;
          }
        }
        h1 {
          margin-bottom: 1em;
        }
        .popular{
          margin-top: 1em;
          a {
            text-decoration: underline;
          }
        }
      }
    }

    .floating-icon {
      position: absolute;
      align-items: center;
      justify-content: center;
      background-color: #F4EBBE;
      border-radius: 50%;
      display: none;
      @include tablet-landscape() {
        display: block;
      }
      &.tool {
        top: 14%;
        left: 10%;
      }
      &.tools {
        top: 30%;
        right: 2%;
      }
      &.toolbox {
        bottom: 2%;
        right: 6%;
      }
      &.fork {
        left: 16%;
        bottom: 28%;
      }
      &.painter {
        bottom: 5%;
        right: 32%;
      }
      @include tablet-portrait() {
        &.tool {
          top: 12%;
          left: 8%;
        }
        &.toolbox {
          bottom: 12%;
          right: 10%;
        }
      }
      @include tablet-landscape() {
        &.tool {
          top: 20%;
          left: 25%;
        }
        &.tools {
          top: 32%;
          right: 20%;
        }
        &.toolbox {
          bottom: 12%;
          right: 8%;
        }
      }
    }
    

    .floating-image {
      position: absolute;
      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        
      }
      
    }
    .floating-image-1 {
      width: 150px;
      height: 150px;
      display: none;
      @include tablet-landscape() {
        display: block;
        right: 10%;
        top: 50%;
      }
    }
    .floating-image-2 {
      width: 80px;
      height: 80px;
      left: 20%;
      bottom: 5%;
      display: none;
      @include tablet-landscape() {
      display: block;
        left: 5%;
        bottom: 2%;
      }
    }
    .floating-image-3 {
      width: 60px;
      height: 60px;
      top: 15%;
      left: 15%;
      display: none;
      @include tablet-landscape() {
      display: block;
        top: 15%;
        left: 20%;
      }
    }
    .floating-image-4 {
      width: 65px;
      height: 65px;
      top: 40%;
      right: -3%;
      display: none;
      @include tablet-landscape() {
       display: block;
        right: 5%;
      }
      @include tablet-landscape() {
        top: 20%;
      right: 15%;
      }
    }
  
  }

  .container {
    text-align: center;
    .content {
      width: 80%;
      margin-inline: auto;
    }
  }

  .container1 {
    margin: 5em auto;
    .text {
      margin-bottom: 3em;
    }
    .instructions {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 2em;
      .box {
        .icon {
          display: inline-block;
          padding: 0.9em 1em;
          background-color: $gray-0;
          border-radius: 50%;
          margin-bottom: 1em;
          cursor: pointer;
         
        }
      }
    }
  }

  
  .container2 {
    margin: 5em auto;
    h2 {
      margin-bottom: 3em;
    }
    .categories {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      gap: 2em;
      .box {
        text-align: left;
        height: 120px;
        background-color: #16475e;
        color:#fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1em;
        cursor: pointer;
        transition: all 0.3s ease;
        &:nth-of-type(5) {
          background-color: $black;
          color: $white;
          &:hover{
            color: $beige;
            background-color: $black;
          }
        }
        &:hover{
          background-color: #16475ebd;;
        }
      }
    }
  }


  .container3 {
    margin: 6em auto 0;
    background-color: $beige;
    padding: 2em;
    @include tablet-landscape() {
      padding: 4em;
    }
    .content {
      width: 90%;
      display: flex;
      flex-direction: column;
      gap: 2em;
      @include tablet-landscape() {
        width: 60%;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;

      }
      .left {
        display: flex;
        flex-direction: column;
        gap: 2em;
        @include tablet-landscape() {
          width: 50%;
          text-align: left;
          button {
            align-self: flex-start;
          }
        }
      }
      .right {
        .image {
          position: relative;
          width: 250px;
          margin-inline: auto;
          @include tablet-landscape() {
            margin: 0;
          }
          img {
            border-top-left-radius: 47%;
            border-top-right-radius: 47%;
          }
          .losange {
            position: absolute;
            width: 40px;
            height: 40px;
            transform: rotate(45deg);
            background-color: $black;
            top: 15%;
          }
          .dots {
            position: absolute;
            display: flex;
            flex-direction: column;
            gap: 0.5em;
            bottom: 20%;
            right: -20%;
            .group {
              display: flex;
              align-items: center;
              gap: 0.5em;
              .dot {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: $black;
              }
            }
          }
        }
      }
    }
  }

  .container4,.container5 {
    background-color: $gray-0;
    padding: 4em 0;
    h2 {
      margin-bottom: 3em;
    }
    .cards {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 2em;
      margin-bottom: 2em;
    }
    .more {
      cursor: pointer;
    }
    .more:hover{
      text-decoration: underline;
      color:green
    }

  }
}

.suggestion-menu{
  border: 1px solid #ffffffb5;
  margin-top: -16px;
  width: 98%;
  max-height: 150px;
  overflow: auto;

}
.suggestion-menu ul{
  text-align: left;
  padding: 0px 24px;

  
}
.suggestion-menu ul li {
  color: #495057;
  font-size: 0.9rem;
}
.suggestion-menu li a{
  text-decoration: none!important;
}
.suggestion-menu li:hover{
  cursor: pointer;
  font-size: 1.0rem;
  color: green;
  font-weight: 800;
  text-decoration: underline;
}
.floating-image:hover > .bigger-image{
  cursor: pointer;
  display: block;
  width: 300px;
  height: 300px;
  border-radius: 150px;
  z-index: 9999;


}
.bigger-image{
  display: none;
  position: absolute;
  top:-12px;
  left: -63px;
  z-index: 1;
  transition: all 4s ease-in;
}

.home button.primary{
  background-color: #16475e;
}
.home .home-searchbar {
  border-color: #16475e;
}
.btn-scroll{
  display: none;
}
.container5{
   padding: .5em 0!important;
}
.container5 h2{
  margin: 1em;
}
.card-container .cards {
  padding: 40px;
  margin-left: 0;
}
// #16475e29
/* Large screen */
@media screen and (min-width: 700px) {
  .container5{
    padding: .5em 0!important;
  }
  .container5 {
    position: relative;

  }
  .container5  h2{
    margin:0;
    margin-bottom: 1em!important;

  }
  .card-container {
    width: 90%;
    overflow-x: hidden;
    padding-bottom: 6px;  
    padding-right: 20px;   
    padding-left: 15px;
    margin: auto auto;   
 
  }
  .card-container .cards{
    display: flex!important;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: fit-content;
    transition: 1s all ease-out;
  
  }
  .card-container .cards .professional-card{
    width: 310px;
  }
  .container5 .btn-scroll{
    display: block;
    position: absolute;
    border-radius: 0;
    height: 50px;
    width: 50px;
    border-radius: 25px;
    text-align: center;
    // top:calc(740px/2);
    font-weight: bold;
   
  }
  .container5 #btn-scroll-left{
    left: 20px;
  
  }
  .container5 #btn-scroll-right{
    right: 20px;
  }
}